import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { SharedState } from './context/SharedState';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SharedState>
    <App />
  </SharedState>
);
