import React, { useContext } from 'react'
import { Context } from '../context/SharedState'

export default function TnC() {
    const states = useContext(Context)
    return (
        <>
            <div className={`container col-sm-6 p-4 mb-5 appearfromLeft rounded  ${states.mode === 'light' ? 'text-dark' : 'text-light'}`} style={states.mode === 'dark' ?
                { backgroundImage: 'url(https://i.ibb.co/nmSkQTC/background.jpg)', marginTop: '12vh' } :
                { background: 'white', marginTop: '12vh', zIndex: 100 }}
                data-bs-theme={states.mode}>
                <div className='h2 appearfromRight'>
                    Terms & Conditions
                </div>
                <hr className='w-50 text-end mb-4 appearfromLeft'/>
                <div>
                    Effective Date: Dec 01, 2024<br />
                    These terms and conditions govern your use of my services, including website development
                    and related projects.
                    <hr />
                    <ol>
                        <li className='fw-bold'>Agreement to Terms</li>
                        By engaging our services, you agree to comply with these terms. If you do not agree,
                        please refrain from using the services.

                        <li className='fw-bold mt-3'>Services Provided</li>
                        <ul>
                            <li>Custom website development using MERN stack technologies.</li>
                            <li>Maintenance and support as agreed upon in the project scope.</li>
                            <li>Other services as specified in individual agreements.</li>
                            <li>The exact deliverables will be outlined in the project contract.</li>
                        </ul>

                        <li className='fw-bold mt-3'>Client Responsibilities</li>
                        Clients are responsible for:
                        <ul>
                            <li>Providing accurate and timely information necessary for project completion.</li>
                            <li>Responding to queries and feedback within the agreed timeframe.</li>
                            <li>Ensuring the legality of the content provided for the project.</li>
                        </ul>

                        <li className='fw-bold mt-3'>Payment Terms</li>
                        <ul>
                            <li>A 20% deposit is required to initiate work.</li>
                            <li>Maintenance and support services are not included in the standard project fees and will incur additional charges.</li>
                            <li>Final payment must be made upon project completion before deployment or handover.</li>
                            <li>Late payments may incur additional fees.</li>
                        </ul>

                        <li className='fw-bold mt-3'>Intellectual Property Rights</li>
                        <ul>
                            <li>The client retains ownership of all content provided for the project.</li>
                            <li>The final deliverable remains our intellectual property until full payment is received.</li>
                        </ul>

                        <li className='fw-bold mt-3'>Confidentiality</li>
                        All information shared between us is confidential and will not be disclosed to third parties without consent.

                        <li className='fw-bold mt-3'>Liability Disclaimer</li>
                        We are not responsible for:
                        <ul>
                            <li>Issues arising from misuse or unauthorized modifications of the project.</li>
                            <li>External factors such as server downtime, cyber-attacks, or third-party service failures.</li>
                        </ul>

                        <li className='fw-bold mt-3'>Termination Clause</li>
                        Either party may terminate the agreement under the following conditions:
                        <ul>
                            <li>Breach of contract terms.</li>
                            <li>Non-payment after two multiple notices.</li>
                        </ul>
                        In such cases, payments for completed work are non-refundable.


                        <li className='fw-bold mt-3'>Updates to Terms</li>
                        We may update these terms as necessary. Clients will be notified of any changes.
                        </ol>

                        <hr/>

                        <b>For any concerns about these terms, please contact at:<br/>
                        <a href='mailto:contact@neupanesugam.com.np' className='text-decoration-none' target='_blank' rel="noopener noreferrer">contact@neupanesugam.com.np</a>
                        </b>




                    
                </div>
            </div>
        </>
    )
}
