import React, { useEffect, useState } from 'react'

export default function Loader() {
    const [count, setCount] = useState(6);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (count > 0) {
                setCount(count - 1);
            } else {
                clearInterval(countdown);
                window.location.reload();
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [count]);

    return (
        <>
                <strong className='d-flex justify-content-center madimi-one-regular fs-4 mb-4 text-dark'>Auto Refresh in {count} seconds</strong>
            <div className="d-flex justify-content-center madimi-one-regular fs-1 mb-4 text-light" style={{ marginTop: '30vh' }}>
                <strong>Great things take time</strong>
            </div>

            <div className="d-flex justify-content-center">
                <div className="loader">
                    <div className="📦"></div>
                    <div className="📦"></div>
                    <div className="📦"></div>
                    <div className="📦"></div>
                    <div className="📦"></div>
                </div>
            </div>
        </>
    )
}
