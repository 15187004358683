import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Context } from '../context/SharedState';
import Alerts from './Alerts';

export default function HireMe() {
    const states = useContext(Context)
    const params = useParams()

    const handleForm = () => {
        states.showAlert(`Server not established. Contact Admin`, "red", 5000);
    }

    if (states.loading) {
        return (" ")
    }

    return (
        <>
            <Alerts alert={states.alert} />
            <div className={`container col-sm-5 p-4 mb-4 appearfromTop  ${states.mode === 'light' ? 'text-dark' : 'text-light'}`} style={states.mode === 'dark' ?
                { backgroundImage: 'url(https://i.ibb.co/nmSkQTC/background.jpg)', marginTop: '15vh' } :
                { background: 'linear-gradient(45deg, #00bcd4,#ffeb3b)', marginTop: '15vh' }}
                data-bs-theme={states.mode}>

                <p className='text-center fs-1 madimi-one-regular' >Enter your details </p>
                <hr className='w-75 m-auto' />
                <form className='p-4 fs-5' >
                    <div className="mb-3">
                        <label className="form-label fw-bold">Your Name</label>
                        <input type="text" className="form-control" placeholder='Enter your full name here' />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">Email address</label>
                        <input type="email" className="form-control" placeholder='@' />
                        <div className="form-text text-secondary">We'll never share your email with anyone else.</div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">Project Details (Document Link)</label>
                        <input type="text" className="form-control" placeholder='Link here' />
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-bold">Plan details:</label>
                        <p className='fs-6'>
                            {params.id.charAt(0).toUpperCase() + params.id.slice(1)} plan selected<br />
                            Plan Price:
                            {params.id === 'basic' ? ` ${states.currency === "INR"
                                ? states.plans.basic.inr.toLocaleString()
                                : states.plans.basic.nrp.toLocaleString()} ${states.currency}` :
                                params.id === 'normal' ? ` ${states.currency === "INR"
                                    ? states.plans.normal.inr.toLocaleString()
                                    : states.plans.normal.nrp.toLocaleString()} ${states.currency}` :
                                    params.id === 'premium' ? ` ${states.currency === "INR"
                                        ? states.plans.premium.inr.toLocaleString()
                                        : states.plans.premium.nrp.toLocaleString()} ${states.currency}` :
                                        'Price not available'}

                            <br />
                        </p>
                        <span className='text-danger fs-6'>I am not accepting the project from here right now. Please contact me via email: contact@neupanesugam.com.np</span>
                    </div>

                    <div className='text-end'>
                        <a className={`btn me-2 ${states.mode === 'light' ? 'btn-outline-dark' : 'btn-outline-light'}`} onClick={states.toggleModal}>Change Plan Details</a>
                        <a className={`btn me-2 ${states.mode === 'light' ? 'btn-success' : 'btn-danger'}`} onClick={() => handleForm()}>Lets Go!</a>
                    </div>
                </form>

            </div>
        </>
    )
}
