import React from 'react'

export default function SkillsAndAcheivements(props) {
    return (
        <>

            <div className={`pb-4 ${props.mode === 'dark' ? 'bg-dark' : ''}`} data-bs-theme={props.mode}>
                <div id="skills" className='madimi-one-regular text-center content-heading-text'> Skills and Achievements </div>

                <hr className="w-75 m-auto" />

                <div className='container-fluid row p-4 m-auto'>
                    <div className='col-sm-4' data-aos="fade-right" data-aos-duration="2000">
                        <div className="progress mb-4" style={{ height: '30px' }}>
                            <div className={`progress-bar progress-bar-striped progress-bar-animated ${props.mode === 'dark' ? 'bg-dark' : 'bg-primary'}`} style={{ width: '90%' }}>HTML/CSS</div>
                        </div>
                        <div className="progress mb-4" style={{ height: '30px' }}>
                            <div className={`progress-bar progress-bar-striped progress-bar-animated ${props.mode === 'dark' ? 'bg-dark' : 'bg-danger'}`} style={{ width: '90%' }}>Bootstrap</div>
                        </div>
                        <div className="progress mb-4" style={{ height: '30px' }}>
                            <div className={`progress-bar progress-bar-striped progress-bar-animated ${props.mode === 'dark' ? 'bg-dark' : 'bg-info'}`} style={{ width: '80%' }}>React JS</div>
                        </div>
                        <div className="progress mb-4" style={{ height: '30px' }}>
                            <div className={`progress-bar progress-bar-striped progress-bar-animated ${props.mode === 'dark' ? 'bg-dark' : 'bg-success'}`} style={{ width: '60%' }}>Express JS / Node JS</div>
                        </div>
                        <div className="progress mb-4" style={{ height: '30px' }}>
                            <div className={`progress-bar progress-bar-striped progress-bar-animated ${props.mode === 'dark' ? 'bg-dark' : 'bg-primary'}`} style={{ width: '70%' }}>Mongo DB</div>
                        </div>
                        <div className="progress mb-4" style={{ height: '30px' }}>
                            <div className={`progress-bar progress-bar-striped progress-bar-animated ${props.mode === 'dark' ? 'bg-dark' : 'bg-warning'}`} style={{ width: '50%' }}>DJANGO</div>
                        </div>

                        <p className='mt-5 fs-5 text-center'>
                            I excel in JavaScript, React, Node.js, and MongoDB, specializing in MERN stack development.
                            I have a keen eye for creating visually stunning and responsive interfaces.
                            My accomplishments include delivering various web projects, such as a personal portfolio and a task management app.
                            I am committed to ongoing learning and embracing new technologies.
                        </p>
                        
                        <div className='mb-3 d-flex justify-content-center'>
                            <a className="button btn" href="https://drive.google.com/file/d/1OfhdfY5wfwfz_mg5Vp6VXge7G-A_q7wt/view?usp=sharing" target='_blank' rel="noopener noreferrer">
                                <span className="button__text">Download Resume</span>
                                <span className="button__icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" id="bdd05811-e15d-428c-bb53-8661459f9307" data-name="Layer 2" className="svg"><path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path><path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path><path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path></svg></span>
                            </a>
                        </div>


                    </div>

                    <div className='col'>

                        <div className="row row-cols-1 row-cols-md-3 g-5">

                            <div className="col col-sm-6" data-aos="flip-right" data-aos-duration="2000">
                                <div className="card text-light h-100">
                                    <img src="https://i.ibb.co/8z6Q0xw/image.png" alt="Infosys SpringBoard" className="card-img-top" height={220} />
                                </div>
                            </div>

                            <div className="col col-sm-6" data-aos="flip-left" data-aos-duration="2000">
                                <div className="card text-light h-100">
                                    <img src="https://i.ibb.co/DVb7NfZ/image.png" alt="Coursera-JS,HTML,CSS" className="card-img-top" height={220} />
                                </div>
                            </div>

                            <div className="col col-sm-6" data-aos="flip-right" data-aos-duration="2000">
                                <div className="card text-light h-100">
                                    <img src="https://i.ibb.co/vsfhVXZ/image.png" alt="GDG Developers" className="card-img-top" height={220} />
                                </div>
                            </div>

                            <div className="col col-sm-6" data-aos="flip-down" data-aos-duration="2000">
                                <div className="card text-light h-100">
                                    <img src="https://i.ibb.co/WDcDRj8/image.png" alt="Udemy-Python and Django" className="card-img-top" height={220} />
                                </div>
                            </div>

                            <div className="col col-sm-6" data-aos="slide-up" data-aos-duration="2000">
                                <div className="card text-light h-100">
                                    <img src="https://i.ibb.co/c28CyLG/image.png" alt="Udemy-Python In depth" className="card-img-top" height={220} />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </>
    )
}
