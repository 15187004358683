import React from 'react'

export default function Footer(props) {

    return (
        <>

            <footer className="bg-dark text-center text-white">
                <div className="row row-cols-1 row-cols-md-2" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                    <div className='col'>
                        <div className="text-center p-3" >
                        Copyright © 2024 Sugam Neupane {props.isDesktop && '| www.neupanesugam.com.np'}
                        </div>
                    </div>

                    <div className='col'>
                        <div className="text-center p-3" >
                            Last Updated: 01 December 2024
                        </div>
                    </div>
     
                </div>
            </footer>

        </>
    )
}
