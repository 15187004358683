import axios from 'axios';
import { useEffect, useState } from 'react';
import { createContext } from "react";

const Context = createContext();

const SharedState = (props) => {
    let hostname;
    if (process.env.NODE_ENV === 'production') {
         hostname = process.env.REACT_APP_HOSTNAME
    } else {
         hostname = process.env.REACT_APP_LOCALHOST
    }

    //check desktop or phone (used in dark mode buttons)
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);

    // State to store the plans data
    const [plans, setPlans] = useState([]);

    // States to store Currency and the corresponding flag
    const [currency, setCurrency] = useState('NRP')
    const [flag, setFlag] = useState('np')

    // Loading state
    const [loading, setLoading] = useState(true);

    // Dark Mode toggle
    const [mode, setMode] = useState(localStorage.getItem('mode') || 'light');

    //For Mode Switch
    const switchMode = () => {
        if (mode === 'dark') {
            setMode('light')
        }
        else {
            setMode('dark')
        }
    };

    // For Alert Message
    const [alert, setAlert] = useState();
    const showAlert = (message, type, timer) => {
        setAlert({
            msg: message,
            type: type
        });
        setTimeout(() => {
            setAlert(null);
        }, timer);
    };

    //For Modal Toggle (HireMe.js)
    const toggleModal = () => {
        const modal = new window.bootstrap.Modal(document.getElementById('exampleModal'));
        modal.toggle()
    }

    useEffect(() => {
        localStorage.setItem('mode', mode);
        setLoading(true)
        // Fetching Plans Data
        axios.get(`${hostname}/api/plansAndPrices`)
            .then((res) => {
                setPlans(res.data.planData);
                setLoading(false)
            })
            .catch((error) => {
                setLoading(true)
                console.error("Error fetching plans:", error);
                showAlert(`Cannot Connect to Server`, "red", 4000);
            });

    }, [mode]);


    return (
        <Context.Provider value={{
            hostname,
            mode, switchMode,
            alert, showAlert,
            isDesktop, setIsDesktop,
            loading, setLoading,
            toggleModal,
            plans, currency, setCurrency,
            flag, setFlag,
        }}>

            {props.children}

        </Context.Provider>
    );
};

export { Context, SharedState };
