import React, { useContext, useEffect, useState } from 'react';
import '../CSS/mainBody.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Context } from '../context/SharedState';


export default function HireMe() {
    const states = useContext(Context)
    const location = useLocation();
    const navigate = useNavigate(); 

    useEffect(() => {
        if (location.search.includes('myplans')) {
            states.toggleModal()
        }
        states.setFlag((states.currency === 'INR') ? 'in' : 'np')

    }, [states.currency]);

    const handleClick = async (plan) => {
        navigate(`/myplans/${plan}`)
        if (location.pathname.includes('/myplans')) {
            await states.showAlert(`Switched to ${plan.charAt(0).toUpperCase() + plan.slice(1)} plan. Price Updated`, "yellow", 4000);
        } else {
            await states.showAlert(`${plan.charAt(0).toUpperCase() + plan.slice(1)} plan selected`, "yellow", 4000);
        }
    }

    if(states.loading) {
        return(" ")
    }

    return (
        <>
            <div className="modal fade" id="exampleModal"
                aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-theme={`${states.mode === 'dark' ? 'dark' : 'light'}`}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ overflowY: 'auto', minHeight: '85vh' }}>
                        <div className="modal-header">
                            <h1 className={`modal-title fs-5 text-${states.mode === 'dark' ? 'light' : 'dark'}`} id="exampleModalLabel">Plans & Pricing</h1>

                            <button type="button" className="btn-close" data-bs-dismiss='modal' aria-label="Close"></button>

                        </div>
                        <div className="modal-body">
                            <p className={`text-center fs-2 fw-bold text-${states.mode === 'dark' ? 'light' : 'dark'}`}>
                                Choose your plan<br /> according to your requirement
                            </p>

                            <div class="dropdown text-center">
                                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Currency: {states.currency} <img src={`https://flagcdn.com/${states.flag}.svg`} alt="Flag" style={{ width: "20px", height: "18px", marginLeft: "3px", marginBottom: '3px', marginRight: '3px' }} />
                                </button>
                                <ul class="dropdown-menu text-end">
                                    <li><a class="dropdown-item" onClick={() => {states.setCurrency('NRP')}} style={{ cursor: 'pointer' }}>
                                        Nepalese Currency <img src="https://flagcdn.com/np.svg" alt="Nepal Flag" style={{ width: "10px", height: "20px", marginLeft: "5px" }} /></a>
                                    </li>
                                    <li><a class="dropdown-item" onClick={() => {states.setCurrency('INR')}} style={{ cursor: 'pointer' }}>
                                        Indian Currency <img src="https://flagcdn.com/in.svg" alt="India Flag" style={{ width: "20px", height: "14px", marginLeft: "5px" }} /></a>
                                    </li>

                                </ul>
                            </div>

                            <div className="row row-cols-1 row-cols-md-3 m-3">

                                <div className="col">
                                    <a onClick={() => handleClick('basic')} className='nav-link user-select-none' data-bs-dismiss='modal'>
                                        <div className="card text-center mt-4 border">
                                            <div className="card-body">
                                                <h3 className={`card-title text-${states.mode === 'dark' ? 'secondary' : 'dark'} fw-bold fs-2`}>
                                                    Basic Plan<span>*</span>
                                                </h3>
                                                <hr />
                                                <p className="card-title fs-4">
                                                    {states.currency === 'INR' ? `₹ ${states.plans.basic?.inr.toLocaleString()}` : `रु ${states.plans.basic?.nrp.toLocaleString()}`} {states.currency}
                                                </p>
                                                <p className='text-muted'>per project</p>
                                                <p className="card-text text-start">
                                                    <span className="material-symbols-outlined fs-5 text-success me-2">task_alt</span>Completion Time: 30 days<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Code access: Frontend only<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Code Explaination<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Backend Code<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Changes in between<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Support after completion<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Vercel Deployment Support<br />
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="col">
                                    <a onClick={() => handleClick('normal')} className='nav-link user-select-none' data-bs-dismiss='modal'>
                                        <div className="card text-center mt-4 border">
                                            <div className="card-body">
                                                <h3 className="card-title text-success fw-bold fs-2">
                                                    Normal Plan<span>*</span>
                                                </h3>
                                                <hr />
                                                <p className="card-title fs-4">
                                                    {states.currency === 'INR' ? `₹ ${states.plans.normal?.inr.toLocaleString()}` : `रु ${states.plans.normal?.nrp.toLocaleString()}`} {states.currency}
                                                </p>
                                                <p className='text-muted'>per project</p>
                                                <p className="card-text text-start">
                                                    <span className="material-symbols-outlined fs-5 text-success me-2">task_alt</span>Completion Time: 15-20 days<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Code access: Both<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Code Explaination<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Backend Code<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Changes in between<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Support after completion*<br />
                                                    <span className="material-symbols-outlined fs-5 text-danger me-2 mt-2">close</span>Vercel Deployment Support<br />
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="col">
                                    <a onClick={() => handleClick('premium')} className='nav-link user-select-none' data-bs-dismiss='modal'>
                                        <div className="card text-center mt-4 border">
                                            <div className="card-body">
                                                <h3 className="card-title text-danger fw-bold fs-2">
                                                    Premium Plan<span>*</span>
                                                </h3>
                                                <hr />
                                                <p className="card-title fs-4">
                                                    {states.currency === 'INR' ? `₹ ${states.plans.premium?.inr.toLocaleString()}` : `रु ${states.plans.premium?.nrp.toLocaleString()}`} {states.currency}
                                                </p>
                                                <p className='text-muted'>per project</p>
                                                <p className="card-text text-start">
                                                    <span className="material-symbols-outlined fs-5 text-success me-2">task_alt</span>Completion Time: 10-15 days<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Code access: Both<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Code Explaination<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Backend Code<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Changes in between*<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Support after completion*<br />
                                                    <span className="material-symbols-outlined fs-5 text-success me-2 mt-2">task_alt</span>Vercel Deployment Support<br />
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={`modal-footer text-muted sticky-bottom bg-${states.mode === 'dark' ? 'dark' : 'light'}`}>
                            * <a className='text-decoration-none' href="" onClick={() => navigate('/terms')} data-bs-dismiss='modal'>Terms and Conditions</a> apply.
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
